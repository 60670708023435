import { AppRouteMeta } from '@sennder/shell-utilities'

import { MicrofrontendId } from './microfrontends'
import {
  canAccessAnalytics,
  canAccessMyAnalytics,
  canAccessNewShipment,
  canAccessOpportunities,
} from './user-access'

/**
 * Unique application route
 */
export type AppRoute = keyof typeof routes

/**
 * All routes of the application, ordered alphabetically by path.
 * Each route has navigation guards, analytical and logging contexts.
 */
export const routes = {
  '/': {
    name: 'dashboard',
    mf: 'shipments',
    context: {
      analytics: {
        module: 'dashboard',
        submodule: '',
      },
      logger: {
        module: 'dashboard',
        codeOwners: 'shipper-portal-pod',
      },
    },
  },
  '/shipments': {
    name: 'my-shipments',
    mf: 'shipments',
    context: {
      analytics: {
        module: 'myShipments',
        submodule: '',
      },
      logger: {
        module: 'myShipments',
        codeOwners: 'shipper-portal-pod',
      },
    },
  },
  '/booking': {
    name: 'new-shipment',
    mf: 'otters-mf-component',
    context: {
      analytics: {
        module: 'newShipment',
        submodule: '',
      },
      logger: {
        module: 'newShipment',
        codeOwners: 'shipper-portal-pod',
      },
    },
    guards: [canAccessNewShipment],
  },
  '/opportunities': {
    name: 'opportunities',
    mf: 'otters-mf-component',
    context: {
      analytics: {
        module: 'spotRequests',
        submodule: '',
      },
      logger: {
        module: 'spotRequests',
        codeOwners: 'shipper-portal-pod',
      },
    },
    guards: [canAccessOpportunities],
  },
  '/analytics': {
    name: 'my-analytics',
    mf: 'otters-mf-component',
    context: {
      analytics: {
        module: 'myAnalytics',
        submodule: '',
      },
      logger: {
        module: 'myAnalytics',
        codeOwners: 'shipper-portal-pod',
      },
    },
    guards: [canAccessAnalytics],
  },
  '/my-analytics': {
    name: 'my-analytics-2',
    mf: 'myanalytics',
    context: {
      analytics: {
        module: 'myanalytics-mf',
        submodule: '',
      },
      logger: {
        module: 'myanalytics-mf',
        codeOwners: 'shipper-portal-pod',
      },
    },
    guards: [canAccessMyAnalytics],
  },
  '/success': {
    name: 'new-shipment-success',
    mf: 'otters-mf-component',
    context: {
      analytics: {
        module: 'newShipment',
        submodule: '',
      },
      logger: {
        module: 'newShipment',
        codeOwners: 'shipper-portal-pod',
      },
    },
  },
} as const satisfies {
  [TRoute: string]: AppRouteMeta<MicrofrontendId>
}
