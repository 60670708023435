import { createApp } from 'vue'

import { loadStyles, themeProvider, Themes } from '@sennder/design-system-core'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import { envOverrides } from '@sennder/shell-utilities'

import '@/assets/tailwind.global.css'

import App from './App.vue'
import router from './router'
import { loadUserData } from '@/store'
import { isLocalEnv, LAUNCH_DARKLY_KEY } from '@/common/config'
import { registerPlugins } from './plugins'
import { logger } from '@/services/logger/loggers'
import { cookieConsentService } from '@/services/CookieConsentService'
import { startTracking } from '@/services/tracking'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { translationProvider } from '@/services/translationProvider'
import { microfrontends } from './config/microfrontends'
import { initRouter } from './router/routes'
import { loadAuthRemoteFunction } from '@/modules/auth'

async function initialize() {
  if (isLocalEnv()) {
    envOverrides.load(Object.values(microfrontends))
  }

  // OPTIONAL: Initialize all data and services here that are independent from token or user data
  if (!LAUNCH_DARKLY_KEY) {
    throw new Error('LAUNCH_DARKLY_KEY not found')
  }

  await Promise.all([
    initLaunchDarkly(LAUNCH_DARKLY_KEY, getLaunchDarklyAnonymousContext()),
    loadAuthRemoteFunction(),
    translationProvider.initialize(),
  ])

  await loadUserData(false)
}

function checkCookieConsent() {
  try {
    const consent = cookieConsentService.didConsentAll
    if (consent) {
      startTracking()
    }
  } catch (error) {
    logger.error(
      `[otters-shell - checkCookieConsent] Error checking cookie consent`,
      { error }
    )
  }
}

async function start() {
  await loadStyles('body')
  themeProvider('html', Themes.Light)
  checkCookieConsent()

  try {
    await initialize()
  } catch (error) {
    logger.error(`Otters shell init`, { error })
  } finally {
    const app = createApp(App)
    await initRouter()
    app.use(router)
    registerPlugins(app)

    app.mount('#app')
  }
}

start()
