
import Veil from '@/components/Veil.vue'
import TransitionFade from '@/components/Transition/TransitionFade.vue'
import { CookieConsentService } from '@/services/CookieConsentService'
import { defineComponent } from 'vue'
import {
  identifyUserInTracking,
  startTracking,
  stopTracking,
} from '@/services/tracking'

export default defineComponent({
  name: 'CookieConsentBanner',
  components: { TransitionFade, Veil },
  emits: ['consentAll', 'consentEssentials'],
  data() {
    return {
      opened: true,
      cookieConsentService: null as CookieConsentService | null,
    }
  },
  created() {
    this.cookieConsentService = new CookieConsentService()
  },
  methods: {
    close() {
      this.opened = false
    },
    consentAll() {
      this.cookieConsentService?.consentAll()
      this.$emit('consentAll')
      startTracking()
      identifyUserInTracking()
      this.close()
    },
    consentEssentials() {
      stopTracking()
      this.cookieConsentService?.consentOnlyEssentials()
      this.$emit('consentEssentials')
      this.close()
      // Reload the page, because there is no way to stop/undo GTM scripts and monitor
      window.location.reload()
    },
  },
})
