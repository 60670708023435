import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'
import { ShipperProfile } from '@sennder/senn-node-microfrontend-interfaces'

export const identifyUserInLaunchDarkly = async (profile: ShipperProfile) => {
  const ldContext: LDContext = {
    kind: 'user',
    key: profile.id,
    email: profile.email,
    name: profile.name,
    tenant: Tenant.SENNDER,
  }
  await identify(ldContext)
  console.info(
    '[otters-shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info(
    '[otters-shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
