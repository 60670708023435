import { getStateData, loadState } from '@/store'
import { identifyUserInAnalytics } from '@/services/analytics'
import { translationProvider } from '@/services/translationProvider'
import { identifyUserInTracking } from '@/services/tracking'
import { auth } from '@/modules/auth'

export const loadUserData = async (onLogin = false) => {
  const token = await auth.value?.getAuthToken()

  if (!token) {
    console.warn('[otters-shell - userDataHelper] - Undefined token')
    return
  }

  if (!(await loadState())) {
    return
  }

  identifyUserInAnalytics({ onLogin })

  await translationProvider.set(getStateData().language)
  identifyUserInTracking()
}
