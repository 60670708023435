import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transition_fade = _resolveComponent("transition-fade")!

  return (_openBlock(), _createBlock(_component_transition_fade, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        "data-veil": "",
        class: "veil",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeVeil && _ctx.closeVeil(...args)))
      })
    ]),
    _: 1 /* STABLE */
  }))
}