import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, isUatEnv } from '@/common/config'
import { getStateCompany, getStateData, getStateProfile } from '@/store'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'

type UserAnalyticsSettings = {
  onLogin: boolean
}

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[otters-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error('[otters-shell - Analytics]: AMPLITUDE_API_KEY not found')
    }
    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'shipper-portal',
      amplitudeConfig: {
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
      },
    })
  }, 'amplitude.init')
}

export function identifyUserInAnalytics({ onLogin }: UserAnalyticsSettings) {
  const analyticsFeaturesFlags = Object.fromEntries(
    Object.entries(getStateData().featureFlags).filter(([flag]) =>
      flag.startsWith('exp')
    )
  )
  const profile = getStateProfile()
  const company = getStateCompany()
  const isInternalUser =
    profile.email.endsWith('@sennder.com') ||
    profile.email.endsWith('@sennder.it')

  const analyticsUser = {
    tenant: getStateData().tenant,
    userId: profile.id,
    companyName: company.companyName,
    language: profile.language,
    shipperUuid: company.id,
    isInternalUser,
    ...analyticsFeaturesFlags,
  }

  executeSafelyWithLogging(
    () =>
      amplitude.identifyUser(
        analyticsUser,
        onLogin,
        {
          groupType: 'shipperUuid',
          groupValue: `${company.id}`,
        },
        ['company id', 'id', 'userName', 'userUuid', 'companyId']
      ),
    'amplitude.identifyUser'
  )
}

export function resetAnalyticsSession() {
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}
