import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'
import { auth } from '@/modules/auth'

export const logout = async () => {
  await auth.value?.logout()
  clearState()
  logger.unsetUserData()
  // We should remove cookie consent here ..

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
