export const getShipperMockedData: any = {
  accountFunction: null,
  accountOwnerEmail: null,
  accountOwnerName: null,
  accountSource: null,
  accountStatus: null,
  accountingId: null,
  billingAddress: null,
  billingEmail: null,
  billingEntityNameOfOrganisation: null,
  billingInstructions: null,
  cmrSubmission: null,
  companyName: null,
  earlyShipperAccess: null,
  financeAgent: null,
  financeAgentEmail: null,
  fuelSurchargeApplies: null,
  guruLink: null,
  id: '123',
  invoicingLegalEntity: null,
  invoicingMethod: null,
  invoicingPeriod: null,
  invoicingTerms: null,
  mothershipId: 123,
  parentId: null,
  primaryContactComments: null,
  primaryContactNumber: null,
  salesforceAccountId: null,
  subContractingPolicy: null,
  topParentId: null,
  userCompany: null,
  vat: {
    countryCode: null,
    number: null,
  },
  children: [],
}

export const successfulResponse = { status: 200 }
