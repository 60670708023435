
import TransitionFade from '@/components/Transition/TransitionFade.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Veil',
  components: { TransitionFade },
  emits: ['close'],
  methods: {
    closeVeil() {
      this.$emit('close')
    },
  },
})
