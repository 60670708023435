<template>
  <transition appear name="fade">
    <slot />
  </transition>
</template>

<style lang="scss">
.fade-enter-active {
  transition: opacity 0.5s linear;
}

.fade-leave-active {
  transition: opacity 0.5s linear;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
