import { ref } from 'vue'

import {
  SharedDataType,
  ILogProvider,
  IAction,
  IActionPermission,
  ICommonHeaders,
  GetTokenOptions,
} from '@sennder/senn-node-microfrontend-interfaces'
import { cloneReactiveToRaw, envOverrides } from '@sennder/shell-utilities'

import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import { getStateFeatureFlags } from '@/store'
import { microfrontends } from '@/config/microfrontends'

export interface AuthRemoteFunction {
  initializeAuthInstance: () => Promise<void>
  isAuthenticated: () => Promise<boolean>
  logout: () => Promise<void>
  getAuthToken: (options?: GetTokenOptions) => Promise<string | undefined>
  getCommonHeaders: (audience?: string) => Promise<ICommonHeaders>
  getPermissions: (
    actions: IAction[],
    audience?: string
  ) => Promise<IActionPermission[]>
}

interface AuthRemoteFunctionClass {
  new (
    shellType: SharedDataType,
    options: {
      logger: ILogProvider
      featureFlags: Record<string, boolean | string>
    }
  ): AuthRemoteFunction
}

export const auth = ref<AuthRemoteFunction>()

export const loadAuthRemoteFunction = async () => {
  const runHotEnv =
    isLocalEnv() && envOverrides.isHot(microfrontends.auth.npmName)

  if (!auth.value) {
    const { default: RemoteFunction } = await microfrontends.auth.getModule<{
      default: AuthRemoteFunctionClass
    }>('./remoteFunctions', runHotEnv ? 'hot' : undefined)

    const featureFlags = getStateFeatureFlags()
    auth.value = new RemoteFunction(SharedDataType.OTTERS, {
      logger,
      featureFlags: cloneReactiveToRaw(featureFlags),
    })
  }

  await auth.value.initializeAuthInstance()
}

export async function getAuthHeader(options?: GetTokenOptions) {
  if (!auth.value) {
    throw new Error('[otters-shell - getAuthHeader]: Auth module is not loaded')
  }
  const token = await auth.value.getAuthToken(options)
  return token ? `Bearer ${token}` : null
}
