import { RouteRecordRaw } from 'vue-router'
import { redirectAuthenticatedUser } from '@/config/route-guards'

export default [
  {
    path: '/login',
    name: 'Login',
    meta: {
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
      analytics: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
      analytics: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
      analytics: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
] satisfies RouteRecordRaw[]
