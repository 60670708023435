import { Navigation } from '@sennder/shell-utilities'

import { AppRoute } from './routes'
import {
  canAccessAnalytics,
  canAccessMyAnalytics,
  canAccessNewShipment,
  canAccessOpportunities,
} from './user-access'

export type AppNavigation = Navigation<AppRoute>

/**
 * Main menu items: categories, leafs (links) and widgets
 */
export const navigation: AppNavigation[] = [
  {
    kind: 'leaf',
    route: '/',
    icon: 'LayoutDashboard',
    translationKey: 'dashboard',
    dataTest: 'dashboard',
  },
  {
    kind: 'category',
    icon: 'Package',
    translationKey: 'shipments',
    dataTest: 'shipments',
    children: [
      {
        kind: 'leaf',
        route: '/shipments',
        translationKey: 'my-shipments',
        dataTest: 'my-shipments',
      },
      {
        kind: 'leaf',
        route: '/booking',
        translationKey: 'new-shipment',
        dataTest: 'new-shipment',
        visibilityHandlers: [canAccessNewShipment],
      },
      {
        kind: 'leaf',
        route: '/opportunities',
        translationKey: 'spot-requests',
        dataTest: 'oppurtunities',
        visibilityHandlers: [canAccessOpportunities],
      },
    ],
  },
  {
    kind: 'leaf',
    route: '/analytics',
    icon: 'ChartNoAxesColumn',
    translationKey: 'my-analytics',
    visibilityHandlers: [canAccessAnalytics],
  },
  {
    kind: 'leaf',
    route: '/my-analytics',
    icon: 'ChartNoAxesColumn',
    translationKey: 'my-analytics',
    visibilityHandlers: [canAccessMyAnalytics],
  },
]
