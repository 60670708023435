import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cookie-consent-banner" }
const _hoisted_2 = {
  key: 0,
  "data-banner": "",
  class: "cookie-consent-banner__wrapper"
}
const _hoisted_3 = { class: "cookie-consent-banner__body" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "cookie-consent-banner__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_veil = _resolveComponent("veil")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_transition_fade, null, {
      default: _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createBlock(_component_veil, { key: 0 }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_transition_fade, null, {
      default: _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  class: "cookie-consent-banner__close",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, [
                  _createVNode(_component_x_icon)
                ]),
                _createElementVNode("p", {
                  class: "cookie-consent-banner__text",
                  innerHTML: _ctx.$t('cookieConsentBanner.text')
                }, null, 8 /* PROPS */, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    class: "consent-essentials",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.consentEssentials && _ctx.consentEssentials(...args)))
                  }, _toDisplayString(_ctx.$t('cookieConsentBanner.cta.essentials')), 1 /* TEXT */),
                  _createElementVNode("button", {
                    class: "consent-all",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.consentAll && _ctx.consentAll(...args)))
                  }, _toDisplayString(_ctx.$t('cookieConsentBanner.cta.all')), 1 /* TEXT */)
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}