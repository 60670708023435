export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const SPS_URL = `${process.env.VUE_APP_API_GATEWAY_URL}/shipper-profile/shipper-portal`
export const SUS_URL = `${process.env.VUE_APP_API_GATEWAY_URL}/shipper-user-service`
export const LAUNCH_DARKLY_KEY = process.env.VUE_APP_LAUNCH_DARKLY_KEY
export const LD_USER_STATIC_KEY = process.env.VUE_APP_LD_USER_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const GTM_CONTAINER_ID = process.env.VUE_APP_GTM_CONTAINER_ID
export const TRANSLATIONS_URL = process.env.VUE_APP_TRANSLATIONS_URL

export const IS_LOCALHOST = window.location.hostname === 'localhost'

export const AUTH0_SUS_AUDIENCE = process.env.VUE_APP_AUTH0_SUS_AUDIENCE

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export function isProductionEnv() {
  return ENV === 'prod'
}

export const FULLSTORY_ORGID = process.env.VUE_APP_FULLSTORY_ORGID
export const PERMISSIONS_SERVICE_URL =
  process.env.VUE_APP_PERMISSIONS_SERVICE_URL
