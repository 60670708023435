import { SharedDataType } from '@sennder/senn-node-microfrontend-interfaces'
import { Fullstory } from '@sennder/shell-utilities'

import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from './analytics'
import { FULLSTORY_ORGID, isLocalEnv, isUatEnv } from '@/common/config'
import { identifyUserInLogger, initLogger, stopLogger } from '@/services/logger'
import { logger } from '@/services/logger/loggers'
import {
  identifyUserInMonitor,
  initMonitor,
  stopMonitor,
} from '@/services/monitor'
import { getStateProfile } from '@/store'
import { initGTM } from '@/plugins/gtManager'

const fullstory = new Fullstory(
  FULLSTORY_ORGID,
  !isLocalEnv() && !isUatEnv(),
  isLocalEnv(),
  logger.error
)

let state: 'stopped' | 'started' = 'stopped'

export function startTracking() {
  if (state === 'started') {
    return
  }
  initLogger()
  initMonitor()
  initAnalytics()
  initGTM()
  fullstory.init()
  state = 'started'

  log('tracking is started')
}

export function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }
  const profile = getStateProfile()

  identifyUserInAnalytics({ onLogin })
  identifyUserInLogger(profile.id, profile.email)
  identifyUserInMonitor(profile.id, profile.email)
  fullstory.identify(SharedDataType.OTTERS, profile.id)

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()
  fullstory.anonymize()
  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopMonitor()
  stopLogger()
  fullstory.stop()
  state = 'stopped'

  log('tracking is stopped')
}

function log(message: string) {
  const prefix = '[otters-shell - tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
