import { AppNavigationGuard } from '@sennder/shell-utilities'

import { DEFAULT_ROUTE } from '@/router'
import { auth } from '@/modules/auth'

/**
 * Router Navigation Guards
 *
 * NAMING CONVENTIONS
 *
 * Route guard name should be a verb that describes what the guard does.
 *
 * - check{Page}Access: Check if the user has access to the page
 * - redirect{Page}: Redirect the user to a different page based on some criteria
 * - restrict{Criteria}: Restrict access to the page based on some criteria
 */
/**
 * Redirects authenticated users to the default route
 */
export const redirectAuthenticatedUser: AppNavigationGuard = async () => {
  const isAuthenticated = !!(await auth.value?.getAuthToken())
  if (isAuthenticated) {
    return { path: DEFAULT_ROUTE }
  } else {
    return true
  }
}
