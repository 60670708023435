import { CacheType, FederatedModule } from '@sennder/federated-module-loader'
import { IOttersSharedData } from '@sennder/senn-node-microfrontend-interfaces'

import { ENV } from '@/common/config'
import { logger } from '@/services/logger/loggers'

const cacheType: CacheType = 'infinite'

/**
 * Unique micro-frontend identifier
 */
export type MicrofrontendId = keyof typeof microfrontends

/**
 * All unique micro-frontends which can be loaded in the application
 */
export const microfrontends = {
  auth: new FederatedModule<any>({
    devPort: 9100,
    environment: ENV,
    moduleFederationPluginName: 'ottersauthmfcomponent',
    npmName: 'otters-auth-mf-component',
    mountExposedName: './ottersauthmfcomponentApp',
    logger,
    cacheType,
  }),
  shipments: new FederatedModule<IOttersSharedData>({
    devPort: 9106,
    environment: ENV,
    moduleFederationPluginName: 'ottersshipments',
    npmName: 'otters-shipments',
    mountExposedName: './ottersshipmentsApp',
    logger,
    cacheType,
  }),
  'otters-mf-component': new FederatedModule<IOttersSharedData>({
    devPort: 9107,
    environment: ENV,
    moduleFederationPluginName: 'ottersmfcomponent',
    npmName: 'otters-mf-component',
    mountExposedName: './ottersmfcomponentApp',
    logger,
    cacheType,
  }),
  myanalytics: new FederatedModule<IOttersSharedData>({
    devPort: 9105,
    environment: ENV,
    moduleFederationPluginName: 'myanalyticsmf',
    npmName: 'myanalytics-mf',
    mountExposedName: './myanalyticsmfApp',
    logger,
    cacheType,
  }),
} as const satisfies {
  [TMicroFrontend: string]: FederatedModule<IOttersSharedData>
}
