import { UserAccessHandler } from '@sennder/shell-utilities'
import { getStateFeatureFlags } from '@/store'

/**
 * Access handlers are functions which return a boolean. They can be used as menu visibility handlers, and called in route guards.
 *
 * NAMING CONVENTIONS
 *
 * Access handler name should be a sentence which describes what access it checks, or user role it checks.
 *
 * - userIs{Role}: Check if the user has a specific role
 * - canAccess{Page}: Check if the user has access to the page
 */

export const canAccessNewShipment: UserAccessHandler = () =>
  !!getStateFeatureFlags()['load-booking']

export const canAccessOpportunities: UserAccessHandler = () =>
  !!getStateFeatureFlags()['opportunities']

export const canAccessAnalytics: UserAccessHandler = () =>
  !!getStateFeatureFlags()['analytics']

export const canAccessMyAnalytics: UserAccessHandler = () =>
  !!getStateFeatureFlags()['my-analytics']
