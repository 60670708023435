import { watch } from 'vue'
import { RouteLocationNormalized, Router } from 'vue-router'
import {
  AppNavigationGuard,
  AppRouteMeta,
  ComponentDefinition,
  createMfWrapper,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'
import { IOttersSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import {
  getMicrofrontendData,
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store'
import router, { DEFAULT_ROUTE } from '@/router'
import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import { loggerInstance } from '@/services/logger'
import { AppRoute, routes } from '@/config/routes'
import { microfrontends } from '@/config/microfrontends'
import { authRoutes } from '@/modules/auth/index'
import { FederatedModule } from '@sennder/federated-module-loader'
import { i18n } from '@/services/i18n'
import { auth } from '@/modules/auth'

const routeNavigationGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const authGuards = to.meta.public ? [] : [isAuthenticatedGuard]
  const guards = [...authGuards, ...(to.meta.guards || [])]
  if (guards.length === 0) {
    return true
  }

  for (const guard of guards) {
    const result = await guard(to, from)
    if (
      typeof result === 'object' ||
      typeof result === 'string' ||
      result === false
    ) {
      return result
    }
  }

  return true
}

const featureFlagWatcher = async () =>
  watch(
    () => getStateData().featureFlags,
    async () => {
      const currentRoute = router.currentRoute.value
      if (!currentRoute) {
        return
      }
      // re-execute navigation guards for current route
      const result = await routeNavigationGuard(currentRoute, currentRoute)
      if (result !== true) {
        if (result === false) {
          router.push(DEFAULT_ROUTE)
        } else {
          router.push(result)
        }
      }
    }
  )

const setPageTitle = (name: string): void => {
  const key = `navigation.item.${name}`
  const pageName = i18n.global.t(key)
  document.title = `${pageName} |  Shipper Portal - sennder`
}

/**
 * Micro-frontends with routes
 */
type AppRouteMicrofrontendId = (typeof routes)[AppRoute]['mf']

const createRouteComponent = async (
  module: FederatedModule<IOttersSharedData>,
  routeMeta: AppRouteMeta<AppRouteMicrofrontendId>
) => {
  const analyticsProvider = new AppAnalyticsProvider(
    routeMeta.context.analytics
  )
  const mfLogger = new MicroFrontendLogger(
    routeMeta.context.logger,
    () => loggerInstance
  )

  return await createMfWrapper<IOttersSharedData>({
    allowEnvironmentOverrides: isLocalEnv(),
    getData: async () => {
      return {
        data: getMicrofrontendData(
          module.npmName === 'otters-auth-mf-component'
        ),
        callbacks: getStateCallbacks(),
        providers: getStateProviders(),
      }
    },
    hooks: {
      failure: logger.error,
    },
    mf: {
      id: module.npmName,
      fml: module,
      context: {
        analytics: routeMeta.context.analytics,
        logger: routeMeta.context.logger,
      },
    },
    providers: {
      getAnalytics: () => analyticsProvider,
      getLogger: () => mfLogger,
    },
    router,
    watchers: [featureFlagWatcher],
  })
}

const addAppRoute = (
  routePath: AppRoute,
  routeMeta: AppRouteMeta<AppRouteMicrofrontendId>,
  component: ComponentDefinition
) => {
  const module = microfrontends[routeMeta.mf]

  const guards = routeMeta.guards || []

  router.addRoute({
    path: routePath,
    name: routeMeta.name,
    component,
    meta: {
      public: routeMeta.public,
      fullscreenLayout: routeMeta.fullscreenLayout,
      guards,
      npmName: module.npmName,
      // TODO: need to translate page titles in POEditor
      // title: `navigation.item.${routeMeta.name}`,
      analytics: routeMeta.context.analytics,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: routeMeta.name,
        meta: {
          guards,
          npmName: module.npmName,
        },
        component,
      },
    ],
  })
}

const registerRoutes = async () => {
  for (const route of authRoutes) {
    router.addRoute(route)
  }

  const componentMap = new Map<string, ComponentDefinition>()

  for (const [path, route] of Object.entries(routes)) {
    const module = microfrontends[route.mf]

    let component = componentMap.get(module.npmName)
    if (!component) {
      component = await createRouteComponent(module, route)
      componentMap.set(module.npmName, component)
    }

    addAppRoute(path as AppRoute, route, component)
  }

  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'not-found',
    meta: {
      public: true,
      fullscreenLayout: true,
    },
    component: () => import('../NotFound.vue'),
  })
}

const initRouteGuards = (router: Router) => {
  router.beforeEach((to, from) => {
    return routeNavigationGuard(to, from)
  })

  router.beforeResolve((to) => {
    const { name } = to
    setPageTitle(String(name))
  })
}

const isAuthenticatedGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized
) => {
  const isAuthenticated = !!(await auth.value?.getAuthToken())
  if (isAuthenticated) {
    return true
  } else {
    return {
      path: '/login',
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

export const initRouter = async () => {
  await registerRoutes()
  initRouteGuards(router)
}
